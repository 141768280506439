.react-tabs {
  // padding-top: 45px;
  width: 100% !important;
  ul {
    li {
      // font-size: 18px;
      text-transform: uppercase;
    }
  }
}
