.chartBuildingType {
  max-height: 100%;
  @media (min-width: 1280px) {
    height: 707px;
  }
  .scrollType {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .chartDetail {
    position: relative;
    .titleDetailChart {
      position: relative;
      padding: 15px 15px 0px 15px;
      .typeBuilding {
        font-size: 19px;
        font-weight: bold;
        display: inline-block;
      }
      .totalDefect {
        font-size: 15px;
        color: #a2a2a2;
        display: inline-block;
      }
    }
  }
  .spinner {
    margin-top: 40%;
  }
}
.loadingChart {
  min-height: 300px;
  // background: #e2e2e2e2;
  position: relative;
  .marginLoading {
    position: absolute;
    right: 0px;
    left: 0px;
    top: 30%;
    bottom: 0px;
    margin: 0 auto;
  }
}

#chartDefectAll-1 {
  width: 1000px !important;
}
#chartDefectAll-2 {
  width: 1300px !important;
}
#chartDefectAll-3 {
  width: 1600px !important;
}
#chartDefectAll-4 {
  width: 2200px !important;
}
#chartDefectAll-5 {
  width: 2800px !important;
}
#chartDefectAll-6 {
  width: 3400px !important;
}
#chartDefectAll-7 {
  width: 4400px !important;
}
#chartDefectAll-8 {
  width: 5000px !important;
}
#chartDefectAll-9 {
  width: 6000px !important;
}
#chartDefectAll-10 {
  width: 8000px !important;
}
#chartDefectDefault {
  width: 100% !important;
}

#chart-defect-all {
  overflow: hidden !important;
}
