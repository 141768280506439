.container-wrapper {
  max-width: 770px;
  margin: 0 auto;
  padding: 4em 0;

  &.upload-page-wrapper {
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    padding-top: 16px;

    .separator-full {
      margin-bottom: 10 !important;
    }

    .table {
      margin-bottom: 0 !important;
    }
  }

  .upload_asset {
    flex: 0 0 100%;
    transition: 0.7s;
    transform: none;

    &.hide-step-1 {
      transform: translate(-100%, 0);
    }
  }

  .upload_elevation {
    flex: 0 0 100%;
    transition: 0.7s;

    //transform: translate(-100%, 0);
    &.show-step-2 {
      //transform: none;
      transform: translate(-100%, 0);
    }
  }

  .title_h2 {
    font-size: 28px;
  }

  .separator_1 {
    width: 18%;
    height: 1px;
    margin: 1% 0;
    background-color: #3e3e3e;
  }

  #dropbox_id {
    height: 185px;
    margin-bottom: 20px;

    #upload-images-here-id {
      .to-upload,
      .done-upload {
        .icon-file {
          width: 30px !important;

          .fa-check {
            color: #00b549;
          }
        }

        td:last-child {
          vertical-align: middle;
          text-align: right;

          .remove {
            cursor: pointer;
          }
        }
      }
    }
  }

  .dropbox_ct {
    padding: 1em 0;

    .card {
      border: 0px;
    }

    .card-body {
      border-radius: 3px;
      border: 0.5em dashed rgba(62, 62, 62, 0.15);
      background-color: rgba(62, 62, 62, 0.05);
      padding: 1em 0;
      border-width: 2px;

      .progress {
        background-color: #ffffff;
      }
    }

    .form-group {
      padding: 1em 0;
    }

    .ct_images {
      padding: 1em 2.5em;
      height: 140px;
      overflow-y: scroll;

      .stt_file {
        display: none;
      }

      .name_file {
        img {
          padding-right: 1em;
        }
      }

      .size_file {
        text-align: right;
      }

      .table-sm {
        td {
          border-top: 0px;
        }
      }
    }

    .btn-outline-secondary {
      margin-right: 15%;
    }

    .select-images {
      margin-right: 0;
      padding: 8px 18px;
      margin-bottom: 15px;
      cursor: pointer;

      &:active {
        background-color: transparent;
        color: #28a745;
      }
    }

    #next-to-step-2 {
      margin-right: 0;
      float: right;
      padding: 6px 21px;
      color: #3e3e3e;
      cursor: pointer;
      border-radius: 0;

      &.btn-outline-dark:hover {
        background-color: transparent;
      }

      i {
        vertical-align: middle;
        margin-left: 5px;
      }
    }

    #back-to-step-1 {
      margin-right: 17px;
      padding: 6px 21px;
      color: #3e3e3e;
      cursor: pointer;
      border-radius: 0;

      &.btn-outline-dark:hover {
        background-color: transparent;
      }

      i {
        vertical-align: middle;
        margin-right: 5px;
      }
    }

    .btn-outline-secondary:hover {
      color: #fff;
      background-color: #60cdf6;
      border: 1px solid #60cdf6;
    }

    .btn-outline-secondary.disabled,
    .btn-outline-secondary:disabled {
      color: #fff;
      background-color: #60cdf6;
      border: 1px solid #60cdf6;
    }

    .btn-outline-success.disabled,
    .btn-outline-success:disabled {
      color: #28a745;
      background-color: transparent;
      padding: 0.7em 2em;
      border: 1px solid #28a745;
      font-size: 13px;
      opacity: 0.5;
    }

    .btn-success {
      color: #28a745;
      background-color: transparent;
      padding: 0.7em 2em;
      border: 1px solid #28a745;
      font-size: 13px;
      font-weight: bold;
      opacity: 1;
    }

    .btn-warning {
      color: #e25757;
      background-color: transparent;
      padding: 0.7em 2em;
      border: 1px solid #e25757;
      font-size: 13px;
      opacity: 1;
      font-weight: bold;
    }

    .btn-warning:not(:disabled):not(.disabled).active,
    .btn-warning:not(:disabled):not(.disabled):active,
    .show > .btn-warning.dropdown-toggle {
      background-color: #e25757;
      color: #fff;
    }

    .btn-outline-warning.disabled,
    .btn-outline-warning:disabled {
      color: #e25757;
      background-color: transparent;
      padding: 0.7em 2em;
      border: 1px solid #e25757;
      font-size: 13px;
      opacity: 0.5;
    }

    .btn-outline-ready.disabled,
    .btn-outline-ready:disabled {
      color: #3e3e3e;
      background-color: transparent;
      padding: 0.7em 2em;
      border: 1px solid #3e3e3e;
      font-size: 13px;
      opacity: 0.5;
    }

    button {
    }

    button:focus {
      outline: none;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }

    .table thead th {
      vertical-align: bottom;
      border-bottom: 1px solid #3e3e3e;
      padding: 1em 0;
      font-size: 14px;
      color: #3e3e3e;
      font-weight: normal;
    }

    tr#remove-me-id td {
      padding: 5px 0;
      font-size: 14px;
      color: #bebebe;
      font-weight: normal;
      border-top: none;
    }

    .separator-full {
      width: 100%;
      border: 1px solid #3e3e3e;
      margin: 10px 0 26px;
    }

    #can-upload-elevation-here-id {
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      .to-upload {
        flex: 0 0 50%;
        margin-bottom: 26px;

        td:nth-child(2) {
          display: flex;
          justify-content: space-between;
          padding: 5px 0 0;

          .img-lat-lon-value {
            width: 287px;
            font-size: 0;

            .img-name {
              font-size: 13px;
              width: 100%;
              padding-right: 10px;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .img-lat {
              font-size: 13px;
              width: 50%;
              padding-right: 10px;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .img-long {
              font-size: 13px;
              width: 50%;
              display: inline-block;
              padding-right: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        &:nth-child(odd) {
          padding-right: 2%;
        }

        &:nth-child(even) {
          padding-left: 2%;
        }

        .upload-elevation-item {
          display: block;
          height: 236px;
          position: relative;
          border: 3px solid rgba(62, 62, 62, 0.31);
          background: #d6d5d538;

          img {
            //width: 100%;
            //height: 236px !important;
            max-height: 100%;
            max-width: 100%;
            width: auto;
            height: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
          }

          &:hover .btn-crop,
          .btn.remove {
            display: block;
          }

          &:hover .btn.remove {
            display: block;
          }

          .btn-crop {
            position: absolute;
            top: 8px;
            right: 40px;
            cursor: pointer;
            display: none;
          }

          .btn.remove {
            position: absolute;
            top: 8px;
            right: 8px;
            //display: none;
            width: 30px;
            height: 30px;
            border-radius: 0;
            background: #b2b2b2;
            padding: 0;

            i {
              font-size: 25px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              position: absolute;
              color: white;
            }
          }

          &.disable-crop {
            .btn-crop {
              display: none;
            }
          }

          .error-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(103, 93, 93, 0.5);
            display: none;

            &:after {
              position: absolute;
              content: '';
              top: 0;
              left: 50%;
              width: 10px;
              height: 100%;
              transform: rotate(45deg);
              background: red;
              opacity: 0.7;
            }

            &:before {
              position: absolute;
              content: '';
              top: 0;
              left: 50%;
              width: 10px;
              height: 100%;
              transform: rotate(-45deg);
              background: red;
              opacity: 0.7;
            }
          }
        }

        .td-lat-long,
        .td-name {
          display: none;
        }
      }
    }
  }
}

.image-crop-popup-wrapper {
  // display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(62, 62, 62, 0.3);
  top: 0;
  left: 0;
  z-index: 1;

  .image-crop-popup {
    width: 100%;
    background: white;
    padding: 31px 197px;
    //margin-top: 200px;
    height: 554px;
    overflow: scroll;
    margin-top: 50px;
    .cropper-wrapper {
      margin: 0 auto;
      height: 376px;
      width: 100%;
    }

    .btn-group {
      width: 100%;
      padding: 15px 0;
      align-items: center;

      #resize-img-bar {
        width: 75%;
        margin-right: 15px;
        height: 5px;
        border-radius: 5px;
        background: #7e7e7e;
        -webkit-appearance: none;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: #dcdcdc;
        }
      }
    }

    .ip-name-img {
      .name-title-error {
        color: #dc1818;
        min-height: 25px;
      }

      #ip-name {
        width: 100%;
      }
    }

    .ip-lat-long {
      display: flex;
      flex-wrap: nowrap;
      padding-bottom: 15px;

      div {
        flex: 0 0 50%;

        &.input-lat {
          padding-right: 10px;

          .lat-title-error {
            color: #dc1818;
            min-height: 25px;
          }
        }

        &.input-long {
          padding-left: 10px;

          .long-title-error {
            color: #dc1818;
            min-height: 25px;
          }
        }

        input {
          width: 100%;
        }
      }
    }

    #crop-button {
      display: block;
      line-height: 18px;
      margin: 0 auto;
      padding: 18px 45px;
    }

    &.zoom-img {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      padding: 40px;

      .cropper-wrapper {
        .cropper-container {
          border: 1px solid;

          .cropper-crop-box {
            display: none;
          }

          .cropper-modal {
            opacity: 0;
          }
        }
      }
    }
  }

  .review-cropped-popup {
    padding: 20px !important;
    text-align: center;
    height: 630px;

    .img-review {
      height: 80%;
      display: block;
      position: relative;
      border: 1px solid rgba(62, 62, 62, 0.31);
      background: #d6d5d538;

      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
      }
    }

    .btn-group {
      margin: 30px 0 0;
      display: block;

      .btn-save {
        margin-right: 15px;
      }

      .btn-cancel {
        margin-left: 15px;
      }
    }
  }
}

.mission-detail {
  &__map {
    height: 700px;
  }
  &__summary {
    min-height: 700px;
  }
}

.app-wrap-form {
  max-width: 770px;
  margin: 0 auto;
}

.btn-upload-table {
  width: 100%;
  margin-top: 10px;
  @media (min-width: 1366px) {
    width: auto;
    margin-top: 0;
  }
}

.defects_map--wrap {
  overflow: auto;
  padding: 50px;
  border: 1px solid #60cdf6;
  background-color: #fff;
  .img-responsive {
    width: 100%;
    height: 100%;
  }
}

.inspection-wrapper {
  position: relative;
}

.svg-inspection {
  max-width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  cursor: crosshair;
}

.sp-defect {
  stroke: red;
  stroke-width: 5;
  fill: none;
  // opacity: 0.4;
}

.face_image_hidden {
  visibility: hidden;
}

.face_image_active {
  visibility: visible;
}

.defect-color-code {
  padding-bottom: 30px;
  text-transform: uppercase;
  .color-code {
    padding: 12.5px 25px;
    display: inline-block;
    vertical-align: middle;
  }

  .color-name {
    color: #3e3e3e;
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    margin-left: 5px;
  }

  .color-description {
    color: #3e3e3e;
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    margin-left: 5px;
  }

  .aesthetic {
    margin-bottom: 10px;

    .color-code {
      background: #efd058;
    }
  }

  .functional {
    margin-bottom: 10px;

    .color-code {
      background: #f5a768;
    }
  }

  .critical {
    margin-bottom: 10px;

    .color-code {
      background: #e25757;
    }
  }
}

.defect-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.defect {
  position: absolute;
  /* z-index: 1000; */
  opacity: 1;
  display: block;
  width: 25px;
  height: 25px;
  text-align: center;
  /* margin: -20px 0 0 -20px; */
  border-radius: 0;
  border: none;
  padding: 0;
  color: #3e3e3e;
  transform: translateX(-50%);
}

.defect:hover {
  z-index: 1001;
  color: none;
}

.defect:focus {
  box-shadow: none;
}
.defect-hightlight-critical:after,
.defect-hightlight-unsafe:after {
  position: absolute;
  content: ' ';
  width: 40px;
  height: 40px;
  background: #ff00002b;
  top: -8px;
  left: -8px;
  border: 1px solid #e25757;
}

.defect-hightlight-functional:after,
.defect-hightlight-require:after {
  position: absolute;
  content: ' ';
  width: 40px;
  height: 40px;
  background: #ff730040;
  top: -8px;
  left: -8px;
  border: 1px solid #f5a768;
}

.defect-hightlight-aesthetic:after,
.defect-hightlight-safe:after {
  position: absolute;
  content: ' ';
  width: 40px;
  height: 40px;
  background: #ffc10733;
  top: -8px;
  left: -8px;
  border: 1px solid #eed94e;
}

.defect-check-mark {
  background-image: url("../static/images/check-mark-icon.png") !important;
  background-size: 100% 100% !important;
  background-color: white !important;
  border: 1px solid;
}

.image-bordered {
  border: 1px solid transparent;
}

.img-active {
  border: 1px solid #cf0 !important;
}

.format-tooltip {
  padding: 8px;
  line-height: 15px;
  max-width: 100px;
  min-width: 85px;
}
.defect-info-card {
  .data-table {
    &__detail {
      border-color: #fff;
    }
  }
}
