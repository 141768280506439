.progress {
  background-color: #ebedf2;
}
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: kt-get-color();
  border-radius: 0.25rem;
}

.bg-primary {
  background-color: #5867dd !important;
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #5867dd;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress-file {
  background-color: #60cdf6;
  transition: width .6s ease;
}
