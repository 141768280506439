.btn {
  &--brand {
    background-color: #60cdf6;
    border: 1px solid #60cdf6;
    font-size: 16px;
    color: #3e3e3e;
    cursor: pointer;
  }
  &--link {
    border-radius: 50px 50px 50px 50px;
    border: 1px solid #bfc1d2;
    background: #bfc1d2;
    color: #ffffff;
  }

  i {
    padding: 5px;
    font-weight: 600;
  }
  &-3a {
    &::before {
      left: 15px;
    }
  }

  &--search {
    height: -moz-calc(100% - 12px);
    height: -webkit-calc(100% - 12px);
    height: calc(100% - 12px);
  }
}

.btn-outline-brand {
  color: #22b9ff;
  border-color: #22b9ff;
}

.btn.btn-pill {
  border-radius: 2rem;
}

.btn-errors {
  color: red;
  border-color: red;
}

.btn-text {
  text-transform: capitalize;
}

.btn-resend {
  width: 150px;
  height: 45px;
  margin-top: 15px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.btn-sharpe {
  margin-right: 5px;
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    span {
      font-size: 14px;
    }
  }
  width: 25px;
  height: 25px;
  background: #357790d9;
  &-circle {
    border-radius: 50%;
  }
  &-rhomb {
    transform: rotate(45deg);
  }
}

.btn-defect-circle {
  color: #3e3e3e;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  display: inline-block !important;
  border-radius: 50% !important;
  min-width: 25px;
  min-height: 25px;
  padding: 3px;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
  &::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
    height: 0;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.btn-defect-rhomb {
  color: #3e3e3e;
  position: absolute;
  text-align: center;
  display: inline-block !important;
  min-width: 25px;
  min-height: 25px;
  padding: 3px;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
  transform: translate(-50%, -50%) rotateY(0deg) rotate(45deg) !important;
  border-radius:0;
  border: none;
  &::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
    height: 0;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    transform: rotate(-45deg);
  }
}

.btn-assign {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;

  &:hover {
    color: #fff;
  }
}

.btn-disabled-assign {
  color: #6c757d;
  background: #fff;
  border-color: #6c757d;

  &:hover {
    color: #6c757d;
  }
}

.btn-brand {
  color: #fff;
  background-color: #60cdf6;
  border-color: #60cdf6;
}

.btn-elevate {
}

button:disabled {
  cursor: not-allowed;
}

#search-icon {
  position: absolute;
  top: 0px;
  right: 15px;
  cursor: pointer;
}

#search-building {
  font-size: 16px;
  padding: 12px 20px 12px 20px;
  border: 1px solid #ddd;
  margin-bottom: 12px;
  width: 100%;
  &:focus {
    outline-color: #007bff;
  }
}

.btn-root {
  padding: 0.95em 4.5em;
  font-size: 16px;
  // color: #3e3e3e;
  position: relative;
  min-height: 56px;

  &:hover {
    cursor: pointer;
  }
}

.btn-cancel {
  background-color: #60cdf6;
  border: 1px solid #60cdf6;
  padding: 7px 15px;
  font-size: 16px;
  color: #3e3e3e;
  line-height: 19px;
  cursor: pointer;
}

.btn-rotate {
  background-color: #60cdf6;
  border: 1px solid #60cdf6;
  padding: 6px 12px;
  font-size: 16px;
  color: #3e3e3e;
  line-height: 19px;
  cursor: pointer;
}

#cancel_upload_id {
  margin-left: 17px;
}

.btn_reset {
  font-size: 14px;
  color: #3e3e3e;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.btn-disable {
  background-color: #123d5f;
  border: 1px solid #60cdf6a1;
  padding: 0.95em 4.5em;
  font-size: 16px;
  color: #fff;
  position: relative;
  min-height: 56px;
  opacity: 0.3;
}

#btn-change-view-mode {
  text-align: right;
  button {
    border-radius: 0;
    &.active {
      background-color: #666;
      color: white;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
.btn.btn-elevate.fa-file-pdf-o:before {
  padding-right: 5px;
}
