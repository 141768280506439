.warp {
  &-building-detail {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.container {
  &-custom__building {
    @media screen and(min-width:1366px) {
      max-width: 1366px;
    }
    @media screen and(min-width:1440px) {
      max-width: 1440px;
    }
    @media screen and(min-width:1536px) {
      max-width: 1536px;
    }
    @media screen and(min-width:1660px) {
      max-width: 1660px;
    }
  }
}
// #navbarResponsive {
//   ul {
//     li {
//       a {
//         padding-left: 10px;
//         padding-right: 10px;
//       }
//     }
//   }
// }
