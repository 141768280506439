.process-bar {
  position: fixed;
  width: 100%;
  z-index: 99;
}
.building_defect {
  .progress {
    .progress-bar {
      position: relative;
      display: inline-block;
      line-height: 30px;
      &:hover {
        cursor: pointer;
      }
      span {
        display: none;
      }
    }

    .bg-completed {
      background-color: #19a88c !important;
    }
    .bg-inprocess {
      background-color: #efd058 !important;
    }
    .bg-pending {
      background-color: #66cc00 !important;
    }
    .bg-inprocess {
      background-color: #efd058 !important;
    }
    .bg-unprocessed {
      background-color: #ffcc33 !important;
    }
    .bg-validating {
      background-color: #79cdcd !important;
    }
    .bg-failed {
      background-color: #ff6600 !important;
    }

    .bg-none {
      background-color: #e5e3e7 !important;
    }

    .bg-safe {
      background-color: #efd058 !important;
    }
    .bg-require {
      background-color: #f5a768 !important;
    }
    .bg-unsafe {
      background-color: #e25757 !important;
    }

    .bg-inprogress {
      background-color: #66cc00 !important;
    }
    .bg-kiv {
      background-color: #ff9900 !important;
    }
    .bg-none-action {
      background-color: #ffcc33 !important;
    }
    .bg-others {
      background-color: #ffcc99 !important;
    }

    height: 30px;
    margin-bottom: 15px;
    label {
      line-height: 30px;
      background: white;
      margin: 0;
    }
    .title_progress {
      padding-left: 10px;
      padding-right: 15px;
      min-width: 120px;
      max-width: 120px;
      width: 120px;
    }
    .title_progress_right {
      padding-left: 15px;
      padding-right: 10px;
      min-width: 60px;
      max-width: 60px;
      width: 60px;
    }
  }
}
