/* Custom.css */

/***Bootstarp Overrides***/
body,
html {
  background-color: #ffffff;
  font-family: "Play-Regular";
  color: #19233c;
  font-size: 15px;
  margin-top: 0px;
  padding: 0px;
  border: 0px;
}
.h1,
h1 {
  font-size: 38px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}

.h1-top {
  font-size: 38px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  background-color: #3e3e3e;
  color: #ffffff;
  padding: 30px 0px;
  margin-top: 0px;
}

.navbar-dark {
  border-bottom: 4px solid #0492c2;
  margin-bottom: 0px;
  margin-top: 0px;
}

.navbar-dark .navbar-nav .nav-link {
  color: #3e3e3e;
  text-transform: uppercase;
  font-family: "Play-Regular";
  font-size: 15px;
  border-left: 1px solid #ffffff;
}
.navbar-dark .navbar-brand {
  font-family: "Play-Regular";
  font-size: 18px;
  color: #3e3e3e;
}
.elevation-white {
  font-family: "Play-Regular";
  border-top: 4px solid #0492c2;
  background-color: #ffffff;
  font-size: 15px;
  color: #0492c2;
  padding: 30px 30px;
}

.elevation-dark {
  font-family: "Play-Regular";
  border-top: 4px solid #0492c2;
  background-color: #3e3e3e;
  font-size: 15px;
  color: #ffffff;
  padding: 30px 30px;
}

.elevation-dark img:hover {
  opacity: 0.5;
}

.bg-h3dgrey {
  background-color: #ffffff;
}

.bg-h3dblack {
  background-color: #000000;
}

.bg-white {
  opacity: 0.7;
  background-color: #ffffff;
  box-shadow: 0 9px 21px 0 rgba(15, 15, 15, 0.02);
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  background-color: #ffffff;
  color: #3e3e3e;
  margin-bottom: 0px;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  background-color: #ffffff;
  color: #3e3e3e;
  margin-bottom: 0px;
}

// .breadcrumb {
//   background-color: #3e3e3e;
//   font-size: 12px;
//   text-transform: uppercase;
//   letter-spacing: 0.1px;
//   font-family: "Play-Regular";
//   text-align: center;
// }
// a.breadcrumb-item {
//   color: #0492c2;
//   font-size: 12px;
//   background-color: #3e3e3e;
// }
// .breadcrumb-item + .breadcrumb-item::before,
// .breadcrumb-item.active {
//   content: ">";
//   color: #0492c2;
//   background-color: #3e3e3e;
// }
// .breadcrumb-item.active {
//   color: #0492c2;
//   background-color: #3e3e3e;
// }

.dropdown-item:hover {
  color: #0492c2;
  background-color: #ffffff;
}
.dropdown {
  margin: 0 0;
}

.dropdown-menu {
  margin: 0 0;
  padding: 0 0;
}

.dropdown-item {
  /* margin: 0 0; */
  color: #3e3e3e;
  background-color: #ffffff;
  font-size: 14px;
  font-family: "Play-Regular";
  text-align: center;
  text-transform: uppercase;
}

.dropdown-divider {
  margin: 0.3rem 0;
  background-color: #3e3e3e;
}

.elevation-table-detail {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

/***App styles***/
.bg-app-main {
  border-radius: 0px;
  /* background-color: #f5f6f8; */
  padding: 50px 40px;
  margin-top: 0px;
  /*margin-bottom: 500px;*/
}
.bg-app {
  border-radius: 0px;
  background-color: #f5f6f8;
  padding: 10px 40px;
  margin-bottom: 100px;
}

.app-defect-summary .col {
  background: white;
  padding: 24px;
  text-align: center;
}
.summary-title {
  /*padding: 17px;*/
  text-align: center;
  /*font-size: 9px;*/
  color: #8b93a7;
}
.app-defect-summary .title {
  color: violet;
  /*letter-spacing: 1.2px;*/
  color: #8b93a7;
  text-align: left;
  text-transform: uppercase;
}
.app-defect-summary .s_row {
  margin-bottom: 15px;
}

.app-building-info .app-defect-summary .col {
  text-align: left;
}

.app-building-info .defects {
  color: #8b93a7;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: 12px;
}

.auto_data {
  margin-right: 0px;
}

.report {
  color: #19233c;
  font-size: 13px;
  border-radius: 0px;
  background-color: #00adff;
}
.report::before {
  background: white;
  padding: 5px;
  width: 30px;
  height: 30px;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.status {
  padding: 7px 10px 5px 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  color: white;
  font-family: "OCRAStd";
}
.status::after {
  content: "_";
}
.status.high {
  background: #f50d35;
}
.status.mid {
  background: yellow;
  color: #19233c;
}
.status.normal {
  background: green;
}

/* General button style (reset) */
.btn-5 {
  border: 2px solid #0492c2;
  font-family: "Play-Regular";
  font-size: 15px;
  color: #ffffff;
  background: #3e3e3e;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 0px;
}

.btn-6,
.btn-7 {
  border: 2px solid #0492c2;
  font-family: "Play-Regular";
  color: #ffffff;
  background: #3e3e3e;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 6px 20px;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 0px;
}

.btn-5:hover,
.btn-6:hover {
  color: #0492c2;
}

.btn-7:hover {
  color: #0492c2;
  background: #3e3e3e;
}

.mission_form {
  text-align: left;
}

.btn .report {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  padding: 25px 80px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 0px;
}

.btn .report:before {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;
  -webkit-font-smoothing: antialiased;
  border-right: #ffffff 3px solid;
}

.icon-cart:before {
  font-family: "Font Awesome 5 Free";
  content: "\f061";
}

.btn .report:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
/* Button 3 */
.btn-3 {
  background: #00adff;
  color: #19233c;
}

.btn-3:hover {
  background: #0098df;
}

.btn-3:active {
  background: #0098df;
  top: 2px;
}

.btn-3:before {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  line-height: 3;
  font-size: 140%;
  width: 60px;
}

/* Button 3a */
.btn-3a {
  padding: 25px 30px 25px 80px;
}

.btn-3a:before {
  background: rgba(0, 0, 0, 0.05);
}

#backtotop {
  /*font-family: 'OCRAStd';*/
  font-size: 12px;
  letter-spacing: 0.1px;
  color: #3e3e3e;
  background: transparent;
  border: none;
  text-transform: uppercase;
  float: right;
  position: fixed;
  cursor: pointer;
  bottom: 50px;
  right: 50px;
  z-index: 9999;
  transition: opacity 0.2s ease-out;
  opacity: 0;
}

#backtotop.show {
  opacity: 1;
}

#backtotop > .fa-chevron-up {
  padding-right: 15px;
}

.bctop {
  margin-bottom: 50px;
}

/*STYLING FOR PAGINATION FOLLOWING BOOTSTRAP3*/
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eeeeee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  cursor: default;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.h3-form-control {
  min-width: 138.5px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
