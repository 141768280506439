.badge {
  padding: 0;
  margin: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  font-size: 0.8rem;
  &--inline {
    height: 0;
    width: auto;
    padding: 0.75rem 0.75rem;
    border-radius: 2px;
    margin-right: 5px;
  }
  &--danger {
    color: #ffffff;
    background: #fd397a;
  }
  &--success {
    color: #ffffff;
    background: #1dc9b7;
  }
  &--brand {
    color: #ffffff;
    background: #374afb;
  }
  &--warning {
    color: #111111;
    background: #ffb822;
  }
}
