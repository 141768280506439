.select2-container--open .select2-dropdown--below {
  border: 1px solid #3e3e3e;
  border-radius: 0;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: #e02234 !important;
  color: #3e3e3e !important;
}

.select2-results__option {
  border-top: 1px solid #3e3e3e;
  text-transform: uppercase;
  padding: 13px 0 14px 16px !important;
  font-size: 11px;
  letter-spacing: 0.8px;
}

.select2-container--default .select2-selection--single {
  height: 54px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 54px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 54px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 54px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 54px !important;
}

.filter-list-mission {
  margin-bottom: 10px;
  // display: inline-block;
  .select2-container {
    .select2-selection--single {
      border-radius: 3px;
      height: 39px;
      border: 1px solid #3e3e3e;

      .select2-selection__rendered {
        line-height: 39px;
        text-transform: uppercase;
        padding-left: 16px;
        letter-spacing: 0.8px;
        font-size: 11px;
      }

      .select2-selection__arrow {
        height: 39px;
      }
    }
  }
}

.custom-selector {
  position: relative;

  select {
    background-color: white;
    border: 1px solid #3e3e3e;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 10px;
  }

  &::after {
    content: " ";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 6px solid #495057;
    right: 25px;
    top: calc(50% - 2.5px);
  }
}

.select-range-dropdown {
  z-index: 10;
}
