.label {
  background-color: rgba(88, 103, 221, 0.1);
  color: #5867dd;
  cursor: text !important;
}
.alert_success {
  color: #60cdf6;
  font-weight: bold;
}
.error_label {
  text-align: left;
  color: #d9534f;
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 14px;
}
