.app-title {
  border-bottom: solid 1px #7a849a;
  font-weight: 300;
  font-size: 28px;
  text-align: left;
  color: #19233c;
  text-transform: uppercase;
  padding: 8px 0px;
  letter-spacing: 1.5px;
}

.app-title-white {
  color: #ffffff;
  border-bottom: solid 1px #7a849a;
  font-weight: 300;
  font-size: 28px;
  text-align: left;
  text-transform: uppercase;
  padding: 8px 0px;
  letter-spacing: 1.5px;
}

.app-title span {
  color: #00adff;
}

.app-filter {
  // width: 275px;
  // margin-left: auto;
  // padding-bottom: 30px;

  .filter-lb {
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #3e3e3e;
    line-height: 14px;
    margin: 0;
    padding-top: 12px;
    padding-left: 5px;
    padding-right: 10px;
    margin-left: auto;
  }

  .select2-container {
    padding: 0;
    width: 170px !important;

    .select2-selection--single {
      border-radius: 0;
      height: 39px;
      border: 1px solid #3e3e3e;

      .select2-selection__rendered {
        line-height: 39px;
        text-transform: uppercase;
        padding-left: 16px;
        letter-spacing: 0.8px;
        font-size: 11px;
      }

      .select2-selection__arrow {
        height: 39px;
      }
    }
  }
}

.elevation-dark {
  padding: 61px 134px 61px;
  border-top: none;

  .row {
    margin: 0;

    .elevation-dark-wrapper {
      padding: 0;

      .app-title-white {
        border-bottom: none;
        padding: 0;
        letter-spacing: 0;
        text-transform: none;
        margin-bottom: 17px;
      }

      .line-under-title {
        background: #ffffff;
      }
    }
  }

  .table-borderless {
    tbody {
      tr {
        td {
          padding: 0px;

          .img-border {
            padding: 17px;
            border: 1px solid transparent;
          }

          .img-active {
            border: solid 1px #60cdf6 !important;
            background: rgba(96, 205, 246, 0.2);
          }

          .serials {
            width: 47px;
            height: 47px;
            background: #ffffff;
            color: #000000;
            border-radius: 50%;
            margin: auto;
            display: inline-flex;
            margin-top: 21px;

            span {
              margin: auto;
            }
          }

          .serials-active {
            background: #60cdf6;
          }

          .face {
            text-decoration: none;
          }
        }
      }
    }

    .slick-elevation {
      padding: 5px;
      margin: 40px 0;
      .img-border {
        padding: 17px;
        border: 1px solid transparent;

        .img-elevation {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .img-active {
        border: solid 1px #60cdf6 !important;
        background: rgba(96, 205, 246, 0.2);
      }

      .serials {
        width: 47px;
        height: 47px;
        background: #ffffff;
        color: #000000;
        border-radius: 50%;
        margin: auto;
        // display: inline-flex;
        margin-top: 21px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        @media (min-width: 1366px) {
          display: inline-flex;
        }

        span {
          margin: auto;
        }
      }

      .serials-active {
        background: #60cdf6;
      }

      .face {
        text-decoration: none;
      }
    }
  }
}

.elevation-white {
  padding: 81px 135px 45px;
  border-top: none;

  .row {
    margin: 0 0 17px;

    // height: 32px;
    .app-title {
      border-bottom: none;
      padding: 0;
      letter-spacing: 0;
      margin-bottom: 0;
      text-transform: none;
      font-weight: normal;
      line-height: 32px;
    }

    .app-filter {
      align-items: center;

      .filter-lb {
        padding-top: 0;
      }

      .mr-2 {
        color: #3e3e3e;
      }

      .mission_filter_severity {
        width: 150px;
      }
    }
  }

  .line-under-title {
    margin-bottom: 44px;
  }

  .face_defects {
    .defects_map {
      width: 53.58%;
      padding: 0 25px 0 0;

      &.building {
        padding-left: 30px;
      }

      .severity-select {
        flex: 0 50%;
        margin-left: auto;
      }

      .filter-by-mission {
        margin-right: 0;
        margin-left: auto;
        width: 100%;
        margin-bottom: 30px;
      }

      .group-wrapper {
        // padding-top: 58px;
        background: #f5f5f5;

        // margin-left: 30px;
        .face_image_wrapper {
          //   margin: 0 57px 0 40px;
          border: 1px solid;
        }

        // .defect-container {
        //   margin-left: 40px;
        //   margin-right: 57px;
        // }
      }
    }

    .defects_details {
      // width: 46.42%;
      &.mission {
        margin-top: 40px;
        padding: 0;

        .table-borderless {
          margin-top: 0 !important;
        }

        .defect_image {
          & > div {
            &:first-child {
              padding-right: 15px;
            }

            &:last-child {
              padding-left: 15px;
            }
          }
        }
      }

      &.building {
        width: 100%;
        padding: 0;
      }

      padding: 0 0 0 30px;

      .defect_image {
        .image_container {
          padding: 0;
          height: 400px;

          .cd-image-container {
            max-height: 400px;
            max-width: 750px;

            &.building {
              max-width: 100%;
            }

            img {
              max-height: 400px;
              object-fit: fill !important;
            }
          }
        }

        .table-borderless {
          margin-top: 20px;

          tr {
            color: #3e3e3e;

            th {
              padding: 0 0 15px 0;
              width: 35%;

              div {
                padding: 26px 0 26px 26px;
                background: #f5f5f5;
              }
            }

            td {
              padding: 0;

              div {
                padding: 26px 0;
                background: #f5f5f5;
              }

              .form-control {
                padding: 0;
              }

              .comment {
                display: none;
                margin-top: 27px;
                padding: 10px 17px;
              }

              .btn-submit-comment {
                margin-top: 35px;
                width: 100%;
                border-radius: 0;
                font-size: 12px;
                padding: 13px 0;
                line-height: 14px;
              }

              &.action-taken {
                .select2-container {
                  padding: 0;
                  margin-left: auto;
                  width: 100% !important;

                  .select2-selection--single {
                    border-radius: 3px;
                    height: 39px;
                    border: 1px solid #3e3e3e;

                    .select2-selection__rendered {
                      line-height: 39px;
                      text-transform: uppercase;
                      padding-left: 16px;
                      letter-spacing: 0.8px;
                      font-size: 11px;
                    }

                    .select2-selection__arrow {
                      height: 39px;
                    }
                  }
                }
              }
            }
          }

          .defect-detail-wrapper {
            display: flex;
            flex-wrap: nowrap;
            color: #3e3e3e;
            padding: 26px;
            font-size: 13px;
            letter-spacing: 0.1px;
            background: #f5f5f5;
            margin-bottom: 15px;
            align-items: center;

            &:last-child {
              align-items: unset;
            }

            .defect-info {
              flex: 0 0 42%;
              font-weight: 600;
              min-height: 38px;
              align-items: center;
              display: inline-flex;
            }

            .defect-value {
              flex: 0 0 58%;
              min-height: 38px;
              align-items: center;
              display: inline-flex;
            }

            .action-taken {
              flex: 0 0 58%;

              &.building {
                display: inline-flex;
                align-items: center;
              }

              .select2-container {
                padding: 0;
                margin-left: auto;
                width: 100% !important;

                .select2-selection--single {
                  border-radius: 3px;
                  height: 39px;
                  border: 1px solid #3e3e3e;

                  .select2-selection__rendered {
                    line-height: 39px;
                    text-transform: uppercase;
                    padding-left: 16px;
                    letter-spacing: 0.8px;
                    font-size: 11px;
                  }

                  .select2-selection__arrow {
                    height: 39px;
                  }
                }
              }

              .comment {
                display: none;
                margin-top: 27px;
                padding: 10px 17px;
              }

              .btn-submit-comment {
                margin-top: 35px;
                width: 100%;
                border-radius: 0;
                font-size: 12px;
                padding: 13px 0;
                line-height: 14px;
              }
            }
          }
        }
      }
    }
  }

  .update-status-wrapper {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(62, 62, 62, 0.3);
    top: 0;
    left: 0;
    z-index: 9999;

    .update-status-modal {
      width: 50%;
      background: white;
      padding: 30px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      text-align: center;
    }
  }
}

#require-latlon {
  display: none;
  color: red;
  padding: 0 0 10px 0;
}

.progress {
  &.time {
    height: 3px;
    margin-top: 68px;
    margin-bottom: 100px;

    .year {
      cursor: pointer;
      position: absolute;
      margin-top: -10px;
      z-index: 1;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: #3e3e3e;

      &.one {
        left: 7%;
      }

      &.two {
        left: 50%;
        transform: translateX(-50%);
      }

      &.three {
        left: 88%;
      }

      &.active-year {
        background: #60cdf6;

        div {
          color: #60cdf6;
        }
      }

      div {
        margin-top: 35px;
        margin-left: -5px;
        color: #3e3e3e;
        font-size: 11px;
        letter-spacing: 0.8px;
        line-height: 12px;
      }
    }

    .progress-bar {
      &.time {
        height: 3px;
        background: #3e3e3e;
      }
    }
  }
}

.section-building-map {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  margin-top: 15px;

  &__left {
    border-right: 1px solid #dee2e6;
  }

  &__right {
  }

  &__list-building {
    padding: 0;
    list-style-type: none;

    li {
      padding: 5px;
      border-bottom: 1px solid #dee2e6;
    }
  }

  .form-search {
    position: relative;
  }

  label {
    padding-right: 15px;
  }

  .group-search {
    position: relative;
    display: inline;

    input {
      padding-left: 40px;
    }

    i {
      position: absolute;
      left: 15px;
      top: 2px;
      bottom: 0px;
      color: #6c7293;
    }
  }

  .widget {
    &__title {
      height: 30px;
    }

    .widget__content {
      h3 {
        padding-top: 20px;
        margin: 0px;
        padding-left: 25px;
      }

      ul {
        padding-left: 0px;
        max-height: 100%;
        height: 600px;
        overflow: hidden;
        margin: 20px 0;
        // padding-left: 25px;
        padding-right: 25px;
        list-style: none;
        overflow-y: auto;

        li {
          line-height: 30px;

          &:hover {
            color: white;
            background-color: #60cdf6;
            cursor: pointer;
            padding-left: 15px;
          }
        }

        .marker-choosen {
          color: white;
          background-color: #60cdf6;
          padding-left: 15px;
        }

        .marker-choosen-error {
          color: white;
          background-color: red;
          padding-left: 15px;
        }
      }
    }

    div {
      div:nth-child(2) {
        color: #fff;
      }
    }
  }

  .cluster {
    img {
      display: none;
    }

    div {
      background-color: #60cdf6;
      color: white !important;
      font-size: 15px !important;
      border-radius: 100%;
      border: 2px solid white;
      height: 53px;
      line-height: 50px !important;
    }
  }
}

.menu-bar-building-detail {
  text-align: right;

  button {
    margin: 0 10px;
    background-color: #e2e2e2;
    border: 1px solid #60cdf6;
    padding: 5px 10px;
    font-size: 16px;
    color: #3e3e3e;
    cursor: pointer;
  }

  .is-enabled {
    background-color: #60cdf6;
  }
}

.elevation-dark-wrapper {
  margin-bottom: 15px;

  h2 {
    margin-bottom: 15px;
  }
}

.form-filter-summary {
  .form-group {
    padding: 0px;

    label {
      font-size: 15px;
    }

    .form-control {
      padding: 0px;
      margin-left: 10px;
      height: 39px;
      font-size: 15px;
      padding: 0 10px;
      min-width: 150px;
      border: 1px solid #ccc;
    }
  }

  table {
    tr {
      td {
        font-size: 13px;
      }
    }
  }
}

.app-title {
  display: inline-block;
  letter-spacing: 0;
}

.face_image_wrapper {
  border: 1px solid #0492c2;
  background: #e2e2e2;
}

.building-map {
  #map {
    a {
      cursor: pointer;

      &:hover {
        color: black;
        text-decoration: none;
      }
    }
  }
}

.defects_details {
  table {
    tr {
      td {
        min-width: 190px;
      }
    }
  }
}

.select-filter {
  font-size: 16px;
  padding: 12px 20px 12px 20px;
  border: 1px solid #ddd;
  margin-bottom: 12px;
  margin-left: 20px;
  background: white;
}

#label-note-filter {
  padding-left: 20px;
  font-size: 16px;
}

.group-filter {
  display: inline-block;
  position: relative;

  .select-filter {
    width: 300px;
  }

  ul {
    position: absolute;
    margin-top: -12px;
    padding: 12px 0 !important;
    z-index: 999;
    max-height: 300px;
    overflow: auto;

    li {
      list-style-type: none;
      padding: 0 20px !important;

      &:hover {
        cursor: pointer;
        background-color: #60cdf6;
        color: white;
      }
    }
  }
}

.building-detail {
  &__summary {
    min-height: 700px;
  }
}

.warp-building-detail {
  .app-title {
    width: 100%;
    @media (min-width: 1366px) {
      width: auto;
    }
  }

  .analyser-form-inline {
    float: none;
    @media (min-width: 1366px) {
      float: right;
    }
  }
}

.section-building-map .group-search input {
  width: 100%;
}

.text-show-detail-image {
  display: none;

  @media (min-width: 1366px) {
    display: block;
  }
}

.row.image-toolbar {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  .col:first-child {
    padding-left: 0;
  }
  .col:last-child {
    padding-right: 0;
  }
}

.btn-show-detail-image {
  width: 100%;
  height: 100%;

  @media (min-width: 1200px) {
    display: none;
  }
}

#building-grid-view {
  .col-item {
    //float: left;
    width: 20%;
    padding: 10px;

    .thumbnail {
      text-align: center;
      height: 175px;
      border: 1px solid #8080806b;
      position: relative;

      &:hover .overlay {
        opacity: 1;
      }

      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 1px;
      }

      .overlay {
        transition: 0.5s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        height: 100%;
        background: #615b5bde;
        text-align: left;

        .text {
          padding-left: 10px;
          color: white;
        }
      }

      .remove {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 30px;
        height: 30px;
        border-radius: 0;
        background: #b2b2b2;
        padding: 0;

        i {
          font-size: 25px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          color: white;
        }
      }

      .upload-elevation {
        position: absolute;
        bottom: 8px;
        right: 0;
        border-radius: 0;
        //background: #b2b2b2;
      }
    }
  }
}

