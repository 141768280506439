@mixin d-flex-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@mixin full-position($position) {
  position: $position;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}