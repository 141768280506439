.c3l-freeze {
  overflow: hidden;
}

/* You can remove this page div in your website */
.compare3layers {
  /* Our normalize css */
  box-sizing: border-box;
  position: relative;
  width: 100%;
  overflow: hidden;

  * {
    margin: 0;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Our image information */
  .top {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0px;
  }

  .middle {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0px;
  }

  .bottom {
    display: block;
    width: 100%;
    height: auto;
  }

  .disableZone {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .scroller {
    align-items: center;
    cursor: ew-resize;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0px;
    width: 40px;
    margin-left: -20px;

    &:before,
    &:after {
      content: " ";
      background: white;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
        rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
      flex: 0 1 auto;
      height: 100%;
      width: 2px;
    }

    &.scroller-middle {
      &:before {
        // height: calc(100% + 80px);
      }
      &:after {
        // height: calc(100% - 80px);
      }
    }

    & .scroller-handle {
      align-items: center;
      border: 2px solid white;
      border-radius: 100%;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
        rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
      box-sizing: border-box;
      display: flex;
      flex: 1 0 auto;
      height: 40px;
      justify-content: center;
      width: 40px;
      background-color: rgba(127, 127, 127, 0.5);

      &:before,
      &:after {
        content: " ";
        border-width: 6px;
        border-image: initial;
        height: 0px;
        width: 0px;
      }

      &:before {
        border-style: inset solid inset inset;
        border-color: rgba(0, 0, 0, 0) white rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
        margin-left: -10px;
        margin-right: 10px;
      }

      &:after {
        border-style: inset inset inset solid;
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) white;
        margin-right: -10px;
      }
    }

    &.vertical {
      cursor: ns-resize;
      flex-direction: row;
      height: 40px;
      width: 100%;
      margin-left: 0;
      margin-top: -20px;

      &:before,
      &:after {
        height: 2px;
        width: 100%;
      }

      & .scroller-handle {
        transform: rotate(90deg);
        box-shadow: rgba(0, 0, 0, 0.2) 3px 0px 1px -2px,
          rgba(0, 0, 0, 0.14) 2px 0px 2px 0px,
          rgba(0, 0, 0, 0.12) 1px 0px 5px 0px;
      }

      &.scroller-middle {
        &:before {
          // width: calc(100% + 80px);
        }
        &:after {
          // width: calc(100% - 80px);
        }
      }
    }
  }
}
