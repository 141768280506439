.widget {
  padding: 15px;
  position: relative;
  &__header {
    padding: 0.5rem 0 1.1rem 0;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #ebedf2;
  }
  &__title {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0;
    color: #6c7293;
  }
  &__content {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    &--title {
      font-size: 54px;
    }
    &--desc {
      font-size: 16px;
    }
  }
  &__scroll {
    overflow: auto;
  }
  &__content--history {
    position: relative;
    height: 495px;
    &:before {
      content: "";
      position: absolute;
      left: 5.85rem;
      width: 0.214rem;
      top: 5px;
      bottom: 5px;
      height: calc(100% - 25px);
      background-color: #e7e9f5;
    }
  }
  &__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px dashed #ebedf2;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      color: #22b9ff;
    }
    &--space {
      justify-content: space-between;
    }
    &--history {
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px dashed #ebedf2;
    }
    &--time {
      display: table-cell;
      font-weight: 500;
      vertical-align: top;
      position: absolute;
      padding-top: 0.18rem;
      color: #a7abc3;
    }
    &--circle {
      border-radius: 50%;
      border: 0.89rem solid white;
      z-index: 1;
      top: 0.2rem;
      left: 5.12rem;
      position: relative;
      i {
        font-size: 1.78rem;
        top: -0.93rem;
        left: -0.57rem;
        position: absolute;
      }
    }
    &--success {
      font-size: 1.78rem;
      top: -0.93rem;
      left: -0.57rem;
      position: absolute;
    }
    &--activity {
      display: table-cell;
      vertical-align: top;
      font-size: 1rem;
      padding: 0.35rem 0 0 6rem;
      color: #a7abc3;
    }
  }
  &__color {
    &--success {
      color: #0abb87 !important;
    }
    &--danger {
      color: #fd397a !important;
    }
    &--info {
      color: #22b9ff !important;
    }
    &--warning {
      color: #ffb822 !important;
    }
  }
  &__action {
    padding: 15px 0;
  }
  &--loading {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #ffffffb5;
  }
  &__checbox {
    width: 50px;
  }
}

.min-header-76 {
  min-height: 76px;
}
.max-height-500 {
  max-height: 500px;
}
.max-height-375 {
  max-height: 375px;
}
.cus-height-270 {
  height: 270px;
}

.cus-height-250 {
  height: 250px;
}

.cus-height-561 {
  height: 382px;
  @media screen and (min-width: 1280px) {
    height: 561px;
  }
}
.react-spinner-material {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
