.select-range {
  padding-left: 0px;
  position: relative;
  width: 200px;
  margin-bottom: 0px;
  > li {
    list-style-type: none;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 1px solid #3e3e3e;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 6px solid #495057;
      position: absolute;
      right: 10px;
      top: calc(50% - 2.5px);
      pointer-events: none;
      z-index: 3;
    }
  }
  &-dropdown {
    padding-left: 0px;
    position: absolute;
    width: 100%;
    z-index: 999;
    background-color: #fff;
    border: 1px solid #495057;
    display: none;
    li {
      padding: 0.375rem 0.75rem;
      list-style-type: none;
      &:hover {
        color: #fff;
        background-color: #007bff;
        cursor: pointer;
      }
    }
    .form-range {
      &:hover {
        background-color: white;
        color: #495057;
        cursor: normal;
      }
      &-input {
        width: 30%;
        border: 1px solid #495057;
        border-radius: 0.25rem;
        padding-left: 3px;
      }
      &-input.error {
        border: 2px solid #d9534f;
      }
      label {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
      }
      &-button {
        border-radius: 0.25rem;
        margin-left: 5px;
      }
    }
  }
}

.display-block {
  display: block !important;
}
