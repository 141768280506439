.backgroundImg {
  position: relative;
  top: 0px;
  width: 100%;
  object-position: 50% 50%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  padding: 100px 0;
  background-image: url("https://static.wixstatic.com/media/e478cb5f2d1f481ba15448f9240c9977.jpg/v1/fill/w_1920,h_1282,al_c,q_85,usm_0.66_1.00_0.01/e478cb5f2d1f481ba15448f9240c9977.webp");
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    opacity: 0.5;
    background: #000;
  }
  font-family: brandon-grot-w01-light, sans-serif;
}
.framePricing {
  min-height: 644px;
  height: auto;

  width: 100%;
  position: relative;
  z-index: 2;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(32, 126, 169, 0.49);
    border: 0px solid #324158;
    border-radius: 30px;
    z-index: -1;
  }
  .headerPricing {
    min-height: 150px;
    .title {
      font-size: 65px;
      color: #fff;

      text-align: center;
      padding: 37px 0 0 0;
    }
    .description {
      position: relative;
      padding: 20px;
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
      font-size: 16px;
      color: #afcbe0;
      text-align: center;
    }
  }
  .middlePricing {
    border: 0px solid rgba(50, 65, 88, 1);
    background-color: rgba(6, 33, 52, 0.59);
    border-radius: 0;
    text-align: center;
    padding: 18px 0px 21px 0;
    margin: 0px 0px 54px 0;
    .buyit {
      font-size: 45px;
      color: #6ea4ca;
      text-decoration: none;
    }
  }
  .bottomPricing {
    padding-left: 20px;
    ul {
      padding-left: 20px;
      list-style-type: disc;
      li {
        color: #afcbe0;
        font-size: 15px;
        line-height: 2em;
      }
    }
  }
}
.framePricingEnterpise {
  min-height: 644px;
  height: auto;

  width: 100%;
  position: relative;
  border: 0px solid rgba(50, 65, 88, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  margin-top: -50px;
  z-index: 2;
  .headerPricing {
    min-height: 150px;
    .title {
      font-size: 65px;
      color: #0c3c60;

      text-align: center;
      padding: 37px 0 0 0;
    }
    .description {
      position: relative;
      padding: 20px;
      grid-area: 2 / 1 / 3 / 2;
      justify-self: start;
      align-self: start;
      font-size: 16px;
      color: #0c3c60;
      text-align: center;
    }
  }
  .middlePricing {
    border: 0px solid rgba(50, 65, 88, 1);
    background-color: rgba(63, 167, 230, 1);
    border-radius: 0;
    text-align: center;
    padding: 18px 0px 21px 0;
    margin: 0px 0px 54px 0;
    position: relative;
    .buyit {
      font-size: 45px;
      color: #daedff;
    }
    .contact {
      color: #fff;
      /* padding: 10px 50px 10px 50px; */
      font-size: 18px;
      background-color: rgba(6, 33, 52, 1);
      border-radius: 30px;
      position: absolute;
      right: 60px;
      left: 60px;
      top: 80px;
      padding: 10px;
    }
  }
  .bottomPricing {
    padding-left: 20px;
    ul {
      padding-left: 20px;
      list-style-type: disc;
      li {
        color: #0c3c60;
        font-size: 15px;
        line-height: 2em;
      }
    }
  }
}

.frameChart {
  background-color: #e4e4e4;
  margin-bottom: 30px;
  padding: 15px;
  h3 {
    span {
      font-weight: bold;
    }
    display: inline-block;
  }
  label {
    font-size: 13px;
    padding-left: 15px;
  }
  @media screen and(max-width:1080px) {
    overflow: hidden;
    max-height: 361px;
  }
}
.frameChartType {
  // background-color: #e4e4e4;
  margin-bottom: 30px;
  padding: 15px;
  h3 {
    span {
      font-weight: bold;
    }
    display: inline-block;
  }
  label {
    font-size: 13px;
    padding-left: 15px;
  }
  @media screen and (max-width: 1080px) {
    height: 752px !important;
  }
}
