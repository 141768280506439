.full_width {
  width: 100%;
  h2 {
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 0px;
  }
  p {
    text-align: center;
    font-size: 14px;
    span {
      font-weight: bold;
    }
  }
}
.currentPlan {
  p {
    font-size: 16px;
    line-height: 35px;
  }
}
