.loading-wrap {
}

.widget--loading {
  .spinner {
    box-sizing: border-box;
    -webkit-animation: sweep 1s linear alternate infinite,
      rota 0.8s linear infinite;
    animation: sweep 1s linear alternate infinite, rota 0.8s linear infinite;
    position: absolute;
    top: 42%;
    left: 48%;
    transform: translate(-50%, -50%);
  }
}

.line-blue {
  width: 100%;
  // background: #aaa8ab;
  padding: 5px 0;
}

.line-under-title {
  width: 124px;
  height: 1px;
  background-color: #3e3e3e;
}

.spinner {
  margin: 0 auto;
  border-color: #5bc0de;
}
