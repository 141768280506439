.viewer {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.viewer iframe {
  display: block;
  border: 0;
  width: 100%;
  height: 100%;
}
.annotations {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}
.annotation {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background: white;
  border: 4px solid black;
  border-radius: 50%;
  z-index: 1;
}
.description {
  box-sizing: border-box;
  position: absolute;
  padding: 30px;
  top: 30px;
  right: 30px;
  width: 240px;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  border-radius: 3px;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
}
.description.--active {
  opacity: 1;
  pointer-events: all;
}
.description iframe,
.description img {
  max-width: 100%;
  height: auto;
}

.road-satellite {
  top: 65px;
  left: 0.5em;
}

.ol-touch .road-satellite {
  top: 80px;
}

.face_overlay {
  background-color: #8bb0ff;
  color: #000;
  display: block;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
  text-decoration: none;
  padding: 0 5px;
}

.face_overlay:hover {
  background-color: #688acf;
}
