.form_sign_in,
.form_upload_mission {
  margin-bottom: 0px;
  .form-group {
    input,
    select {
      height: 54px;
      line-height: 54px;
      border: 1px solid #3e3e3e;
    }
    .form-control.error {
      border: 2px solid #d9534f;
    }
  }
  .form-control {
    border: 1px solid #495057;
    padding: 1em;
    height: 54px;
  }
}

.form-control.PhoneInput input{
  outline: none;
  border: none;
}